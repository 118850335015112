// types
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

// initial state
const initialState = {
  tickets: [],
  cartId: null,
  bookingId: null,
  total: null,
  tabby_amount: null,
  tabby_percentage: null,
  stripeClientSecret: null,
  tabbyMinimumAmt: null,
  allowedPaymentMethods: null,
  checkoutTimer: null,
  checkoutTime: null,
};

// ==============================|| SLICE - MENU ||============================== //

const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addTicket(state, action) {
      const { eventId, eventName, tickets, TMSlotsData, slug, parentId } =
        action.payload;

      let allTickets = state.tickets.filter((el, i) => el.eventId !== eventId);
      let loalIdsTickets = tickets.map((el, i) => {
        return { ...el, uuId: uuidv4() };
      });
      //save only last 3 events tickets to avoid localstorage e
      allTickets.unshift({
        eventId,
        eventName,
        slug,
        tickets: loalIdsTickets,
        TMSlotsData,
        parentId,
      });
      if (allTickets.length > 10) {
        allTickets.pop();
      }
      let finalTickets = []; //remove tickets having totals = 0
      allTickets.forEach((event, i) => {
        for (let el of event.tickets) {
          if (el.totals > 0) {
            finalTickets.push(event);
            break;
          }
        }
      });

      return { ...state, tickets: finalTickets };
    },
    deleteTicket(state, action) {
      const { uuId, eventId } = action.payload;

      let allTickets = state.tickets.map((event, i) => {
        if (event.eventId !== eventId) {
          return {
            ...event,
          };
        } else {
          return {
            ...event,
            tickets: event.tickets.map((t) => {
              if (t.uuId !== uuId) {
                return t;
              } else {
                return {
                  ...t,
                  totals:
                    t.totals > 0
                      ? t.totals - (t.ticket_grouping ?? 1)
                      : t.totals,
                };
              }
            }),
          };
        }
      });
      let finalTickets = [];
      allTickets.forEach((event, i) => {
        for (let el of event.tickets) {
          if (el.totals > 0) {
            finalTickets.push(event);
            break;
          }
        }
      });

      return { ...state, tickets: finalTickets };
    },
    deleteChildTickets(state, action) {
      const { ticketId } = action.payload;

      let allTickets = state.tickets.map((event, i) => {
        return {
          ...event,
          tickets: event.tickets.map((t) => {
            if (t.ticket_id !== ticketId) {
              if (t.parent_id === ticketId) {
                return {
                  ...t,
                  totals: 0,
                };
              } else {
                return t;
              }
            } else {
              return t;
            }
          }),
        };
      });
      let finalTickets = [];
      allTickets.forEach((event, i) => {
        for (let el of event.tickets) {
          if (el.totals > 0) {
            finalTickets.push(event);
            break;
          }
        }
      });

      return { ...state, tickets: finalTickets };
    },
    deleteTicketByDbId(state, action) {
      const { ticketId } = action.payload;

      let allTickets = state.tickets.map((event, i) => {
        return {
          ...event,
          tickets: event.tickets.map((t) => {
            if (t.ticket_id !== ticketId) {
              return t;
            } else {
              return {
                ...t,
                totals: 0,
              };
            }
          }),
        };
      });
      let finalTickets = [];
      allTickets.forEach((event, i) => {
        for (let el of event.tickets) {
          if (el.totals > 0) {
            finalTickets.push(event);
            break;
          }
        }
      });

      return { ...state, tickets: finalTickets };
    },
    updateTicketByDbId(state, action) {
      const { ticketId, newDetails, eventId } = action.payload;

      const ticketupdates = { name: newDetails.name };
      const eventUpdates = { eventName: newDetails.eventName };

      let allTickets = state.tickets.map((event, i) => {
        if (eventId == event.eventId) {
          event = {
            ...event,
            ...eventUpdates,
          };
        }
        return {
          ...event,
          tickets: event.tickets.map((t) => {
            if (t.ticket_id !== ticketId) {
              return t;
            } else {
              if (ticketupdates.name) {
                t = { ...t, ...ticketupdates };
              }
              return {
                ...t,
              };
            }
          }),
        };
      });
      let finalTickets = [];
      allTickets.forEach((event, i) => {
        for (let el of event.tickets) {
          if (el.totals > 0) {
            finalTickets.push(event);
            break;
          }
        }
      });
      return { ...state, tickets: finalTickets };
    },
    applyPromoCodeByDBId(state, action) {
      const { ticketId, code, discoutPercent, promocodeType, discountType } =
        action.payload;

      let allTickets = state.tickets.map((event, i) => {
        return {
          ...event,
          tickets: event.tickets.map((t) => {
            if (t.ticket_id !== ticketId) {
              return t;
            } else {
              return {
                ...t,
                promoData: {
                  code,
                  discoutPercent,
                  promocodeType,
                  discountType,
                },
              };
            }
          }),
        };
      });
      return { ...state, tickets: allTickets };
    },
    removeAllPromoCodes(state, action) {
      let allTickets = state.tickets.map((event, i) => {
        return {
          ...event,
          tickets: event.tickets.map((t) => {
            return { ...t, promoData: null };
          }),
        };
      });
      return { ...state, tickets: allTickets };
    },
    checkoutCart(state, action) {
      const {
        booking_id,
        cart_id,
        total_amount,
        tabby_amount,
        stripe_client_secret,
        checkoutTimer,
      } = action.payload;
      return {
        ...state,
        bookingId: booking_id,
        cartId: cart_id,
        total: total_amount,
        tabby_amount: tabby_amount,
        stripeClientSecret: stripe_client_secret,
        checkoutTimer: checkoutTimer,
        checkoutTime: new Date().getTime(),
      };
    },
    clearCart(state, action) {
      return { ...initialState };
    },
    updatePaymentMethodDetails(state, action) {
      return {
        ...state,
        allowedPaymentgateways: action.payload.allowedPaymentgateways,
        tabbyMinimumAmt: action.payload.tabbyMinimumAmt,
        tabby_percentage: action.payload.tabby_percentage,
      };
    },
  },
});

export default cart.reducer;

export const {
  addTicket,
  clearCart,
  deleteTicket,
  deleteTicketByDbId,
  deleteChildTickets,
  updateTicketByDbId,
  applyPromoCodeByDBId,
  removeAllPromoCodes,
  checkoutCart,
  updatePaymentMethodDetails,
} = cart.actions;
